<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.897 20.9972V12.8012H16.662L17.073 9.59217H13.897V7.54817C13.897 6.62217 14.155 5.98817 15.484 5.98817H17.168V3.12717C16.3487 3.03936 15.5251 2.99696 14.701 3.00017C12.257 3.00017 10.579 4.49217 10.579 7.23117V9.58617H7.83203V12.7952H10.585V20.9972H13.897Z"
      fill="white"
      fill-opacity="0.87"
    />
  </svg>
</template>
